import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './config.dev'; // Import URL configurations for API calls
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './Triage.css';
import Topbar from './Topbar'; // Import the Topbar component


function Triage() {
    const [newPatientDetails, setNewPatientDetails] = useState({
        firstName: '',
        lastName: '',
        contactId: '',
        phoneNumber: '',
        age: '',
        sex: '',
        religion: '',
        dob: '',
        address: ''
    });
    
    const [bloodPressure, setBloodPressure] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [temperature, setTemperature] = useState('');
    const [spo2, setSpo2] = useState('');
    const [bodyWeight, setBodyWeight] = useState('');
    const [labTestsAvailable, setLabTestsAvailable] = useState([]);
    const [selectedLabTests, setSelectedLabTests] = useState([]);
    const [radiologyTestsAvailable, setRadiologyTestsAvailable] = useState([]);
    const [selectedRadiologyTests, setSelectedRadiologyTests] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [savePatientClicked, setSavePatientClicked] = useState(false);
    const [showAddPatientPrompt, setShowAddPatientPrompt] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [readOnlyPatientDetails, setReadOnlyPatientDetails] = useState(null);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
    const [editableFields, setEditableFields] = useState({});
    const [showLabTestPrompt, setShowLabTestPrompt] = useState(false);
    const [showRadiologyTestPrompt, setShowRadiologyTestPrompt] = useState(false);
    const [submitting, setSubmitting] = useState(false); // State to track submission status
    const [selectedOption, setSelectedOption] = useState('');
    const [signsAndSymptoms, setSignsAndSymptoms] = useState('');
    const [spo2Status, setSpo2Status] = useState('');
    const [systolicBloodPressure, setSystolicBloodPressure] = useState('');
    const [diastolicBloodPressure, setDiastolicBloodPressure] = useState('');
    const [bloodPressureClassification, setBloodPressureClassification] = useState('');
    const [spo2Classification, setSpo2Classification] = useState('');
    const [temperatureClassification, setTemperatureClassification] = useState('');
    const [respiratoryRateClassification, setRespiratoryRateClassification] = useState('');
    const [pulseRate, setPulseRate] = useState('');
    const [temperatureStatus, setTemperatureStatus] = useState('');
    const [respiratoryRate, setRespiratoryRate] = useState('');
  
    // State for classification of both pulse rate and blood pressure
    const [pulseRateStatus, setPulseRateStatus] = useState({ status: '', level: '' });
    const [bloodPressureStatus, setBloodPressureStatus] = useState({ status: '', level: '' });
    const [attention, setAttention] = useState('');
    const [visitClassification, setVisitClassification] = useState('');
    const [attentionLevel, setAttentionLevel] = useState('');
const [patientNature, setPatientNature] = useState('');

    const [respiratoryRateStatus, setRespiratoryRateStatus] = useState('');
const [classification, setClassification] = useState({ text: '', className: '' });
    const [height, setHeight] = useState(''); // Initialize height
    const [bmi, setBmi] = useState(''); // Initialize BMI
    const [bmiWarning, setBmiWarning] = useState(''); // Initialize BMI warning
    const [pulseRateClassification, setPulseRateClassification] = useState({ text: '', className: '' });
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token'); // Define urlToken here

    const [selectionState, setSelectionState] = useState({
        doctor: false,
        lab: false,
        radiology: false,
    });
 

    const toggleAttentionLevel = (level) => {
        setAttentionLevel(level);
    };
    
    useEffect(() => {
        const fetchEmployeeName = async () => {
            try {
                // Fetch token from URL parameter
                const params = new URLSearchParams(window.location.search);
                const tokenFromUrl = params.get('token');
    
                console.log('URL Token:', tokenFromUrl);
    
                // Perform security check using the token
                const securityResponse = await fetch(urls.security, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: tokenFromUrl }),
                });
    
                if (securityResponse.ok) {
                    const securityData = await securityResponse.json();
    
                    console.log('Security Data:', securityData);
    
                    if (securityData.message === 'Session valid') {
                        // Security check passed, set the employee name
                        setEmployeeName(securityData.employee_name);
    
                        // Store token in localStorage for subsequent requests
                        localStorage.setItem('token', securityData.clinic_session_token);
    
                        // Proceed with fetching lab tests and radiology tests
                        fetchAvailableLabTests();
                        fetchAvailableRadiologyTests();
                    } else if (securityData.error === 'Session expired') {
                        // Redirect to dashboard if session expired
                        console.log('Session expired, redirecting to dashboard');
                        navigate(`/dashboard?token=${securityData.clinic_session_token}`);
                    } else {
                        // Handle other errors and log the error
                        console.log('Other error:', securityData.error);
                        // Navigate to appropriate error page or handle as needed
                        navigate('/login');
                    }
                } else {
                    throw new Error('Failed to perform security check');
                }
            } catch (error) {
                console.error('Error performing security check:', error);
                // Redirect to login page on error
                navigate('/login');
            }
        };
    
        // Effect runs on mount and whenever `navigate` changes
        fetchEmployeeName();
    }, [navigate]);
    
      
    const handleCancel = () => {
        // Reset patient details
        setNewPatientDetails({
            firstName: '',
            lastName: '',
            contactId: '',
            phoneNumber: '',
            age: '',
            sex: '',
            religion: '',
            dob: '',
            address: ''
        });

        setShowAddPatientPrompt(false);
        setSuggestions([]);
    };
    const updateReadOnlyPatientDetails = (newDetails) => {
        setReadOnlyPatientDetails(newDetails);
    };
    const addContact = async () => {
        // Check if the "Save as New Patient" button has already been clicked
        if (savePatientClicked) {
          return; // Do nothing if the button has already been clicked
        }
      
        // Set the "Save as New Patient" button as clicked
        setSavePatientClicked(true);
      
        // Validate required fields
        if (
          !newPatientDetails.firstName ||
          !newPatientDetails.lastName ||
          !newPatientDetails.phoneNumber ||
          !newPatientDetails.age ||
          !newPatientDetails.sex ||
          !newPatientDetails.religion ||
          !newPatientDetails.dob ||
          !newPatientDetails.address // Check if address is provided
        ) {
          toast.error('Please fill in all required fields including address.');
          setSavePatientClicked(false); // Reset the button state
          return;
        }
      
        // Fetch token from URL parameter
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
      
        // Proceed with submitting the form
        try {
          const response = await fetch(urls.addcontact2, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...newPatientDetails, token: tokenFromUrl }),
          });
          const data = await response.json();
          if (response.ok) {
            // Update read-only patient details with newly saved patient data
            updateReadOnlyPatientDetails(data.patientDetails);
            // Display success message
            toast.success(data.message);
            // Disable the "Save as New Patient" button after successful submission
            setSavePatientClicked(true);
      
            // Hide the prompt
            setShowAddPatientPrompt(false);
          } else {
            throw new Error('Failed to add new patient');
          }
        } catch (error) {
          console.error('Error adding new patient:', error);
          // Show an error message
          toast.error('Oops! Something went wrong.');
          // Reset the "Save as New Patient" button state
          setSavePatientClicked(false);
        }
      };
      const handleSelectSuggestedPatient = (patient) => {
        // Set the selected patient details
        setNewPatientDetails({
            firstName: patient.first_name,
            lastName: patient.last_name,
            contactId: patient.contact_id,
            phoneNumber: patient.phone_number,
            age: patient.age,
            sex: patient.sex,
            religion: patient.religion,
            dob: patient.dob,
        });
    
        // Set the state of read-only fields with confirmed patient details
        setReadOnlyPatientDetails({
            firstName: patient.first_name,
            lastName: patient.last_name,
            contactId: patient.contact_id,
            phoneNumber: patient.phone_number,
            age: patient.age,
            sex: patient.sex,
            religion: patient.religion,
            dob: patient.dob,
        });
    
        // Clear the suggestions array
        setSuggestions([]);
    
        // Additional reset operations if needed can be added here
    
        // Clear the data and hide the prompt
        setNewPatientDetails({
            firstName: patient.first_name,
            lastName: patient.last_name,
            contactId: patient.contact_id,
            phoneNumber: patient.phone_number,
            age: patient.age,
            sex: patient.sex,
            religion: patient.religion,
            dob: patient.dob,
        });
    
        // Clear the suggestions array
        setSuggestions([]);
        
        // Optionally, hide the modal if you want to close it after selection
        setShowAddPatientPrompt(false);
    };
    
// Function to handle suggesting patient names
const handleSuggestName = async (name) => {
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const queryString = `?name=${encodeURIComponent(name)}&token=${tokenFromUrl}`;
      const response = await fetch(`${urls.suggest}${queryString}`);
      
      if (response.ok) {
        const data = await response.json();
        
        if (data.message && data.message === 'No patient records found for the specified clinic') {
          setSuggestions([]);
          setMessage('No patient records found');
          // Enable the "Save as New Patient" button
         
          // Disable the "Confirm Patient" button
          setConfirmButtonDisabled(true);
        } else if (data.error === 'Name and token parameters are required'){
            setSuggestions([]);
            setMessage('No patient records found');
            // Enable the "Save as New Patient" button
            
            // Disable the "Confirm Patient" button
            setConfirmButtonDisabled(true);
        
        } else {
          setSuggestions(data);
          setMessage(''); // Clear any previous error message
          // Disable the "Save as New Patient" button
         
          // Enable the "Confirm Patient" button
         
        }
      } else {
        const errorData = await response.json();
        
        if (errorData.error === "Name parameter is required") {
          // Handle the specific error response
          // You can choose not to show any alert for this error
          console.log("Name parameter is required");
          // Return early or perform any other actions you want for this specific error
          return;
        }
        throw new Error('Failed to suggest names');
      }
    } catch (error) {
        console.error('Error suggesting names:', error.message);
        // Show an alert for unexpected errors
        
        // Reset suggestions and set button states
        setSuggestions([]);
        setMessage('No patient records found');
        
    }
};
   
   // Function to display random toast message
const generateRandomToast = () => {
    const messages = [
        'Form is being submitted...',
        'Please wait, processing your request...',
        'Submitting your data, hang tight!',
    ];
    const randomMessage = messages[Math.floor(Math.random() * messages.length)];
    toast.info(randomMessage);
};

const submitForm = async () => {
    try {
        generateRandomToast();
        setSubmitting(true);

        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');

        // Log the selected lab tests before submitting
        console.log('Selected Lab Tests before form submission:', selectedLabTests);

        if (!selectionState.doctor && !selectionState.lab && !selectionState.radiology) {
            toast.error('Please select at least one option (doctor, lab, or radiology).', {
                autoClose: false, // Make toast persistent
                closeOnClick: true // Allow manual closing
            });
            setSubmitting(false);
            return;
        }

        if (!attentionLevel || !patientNature) {
            toast.error('Attention and Visit Classification are required.', {
                autoClose: false,
                closeOnClick: true
            });
            setSubmitting(false);
            return;
        }

        // Check if the action is 'Going to the doctor' and ensure lab and radiology tests are not selected
        if (selectionState.doctor && (selectedLabTests.length > 0 || selectedRadiologyTests.length > 0)) {
            toast.error(
                "If you're sending this patient to the doctor, do not request lab or radiology tests. The doctor will handle these tests.", 
                {
                    autoClose: false,
                    closeOnClick: true
                }
            );
            setSubmitting(false);
            return;
        }

        const formData = {
            contactId: readOnlyPatientDetails.contactId,
            bloodPressure: bloodPressure || '',
            temperature: temperature || '',
            spo2: spo2 || '',
            bodyWeight: bodyWeight || '',
            height: height || '',
            respiratory_rate: respiratoryRate || '',
            pulse_rate: pulseRate || '',
            labTests: selectionState.lab && selectedLabTests.length ? selectedLabTests : [],
            radiologyExams: selectionState.radiology && selectedRadiologyTests.length ? selectedRadiologyTests : [],
            token: tokenFromUrl,
            action: selectionState.doctor ? 'Going to the doctor' : '',
            attention: attentionLevel,
            visitClassification: patientNature,
            signsAndSymptoms: signsAndSymptoms || ''
        };

        // Log the final payload to be submitted
        console.log('Generated Payload:', formData);

        const urlToUse = formData.action === 'Going to the doctor' ? urls.gotodoctor : urls.submitwalkinpt;

        const submitResponse = await fetch(urlToUse, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (submitResponse.ok) {
            const responseData = await submitResponse.json();
            if (responseData.message === 'Data inserted successfully') {
                toast.success('Patient form submitted successfully', {
                    autoClose: false, // Persistent success message
                    closeOnClick: true
                });
                setTimeout(() => {
                    setSubmitting(false);
                    // Refresh the page after a successful submission
                    window.location.reload(); 
                }, 10000);
            } else {
                toast.error('Unexpected response from server', {
                    autoClose: false,
                    closeOnClick: true
                });
                setSubmitting(false);
            }
        } else {
            toast.error('Error submitting patient data', {
                autoClose: false,
                closeOnClick: true
            });
            setSubmitting(false);
        }
    } catch (error) {
        toast.error('Error submitting form! Make sure you have inserted the patient details.', {
            autoClose: false,
            closeOnClick: true
        });
        setSubmitting(false);
    }
};

    const displayReadOnlyFields = () => {
        if (readOnlyPatientDetails) {
            return (
                <div className="read-only-fields-container">
                    <div className="input-container">
                        <label>Patient ID:</label>
                        <input type="text" value={readOnlyPatientDetails.contactId} readOnly />
                    </div>
                    <div className="input-container">
                        <label>First Name:</label>
                        <input type="text" value={readOnlyPatientDetails.firstName} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Last Name:</label>
                        <input type="text" value={readOnlyPatientDetails.lastName} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Phone Number:</label>
                        <input type="text" value={readOnlyPatientDetails.phoneNumber} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Age:</label>
                        <input type="text" value={readOnlyPatientDetails.age} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Sex:</label>
                        <input type="text" value={readOnlyPatientDetails.sex} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Religion:</label>
                        <input type="text" value={readOnlyPatientDetails.religion} readOnly />
                    </div>
                    <div className="input-container">
                        <label>Date of Birth:</label>
                        <input type="text" value={readOnlyPatientDetails.dob} readOnly />
                    </div>
                </div>
            );
        } else {
            return null; // Render nothing if patient details are not available
        }
    };
    
 

    const formatDate = (date) => {
        return date ? new Date(date).toISOString().split('T')[0] : '';
    };
    const fetchAvailableLabTests = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const tokenFromUrl = queryParams.get('token'); // Extract the token from the query string
            if (!tokenFromUrl) throw new Error('Token not found in URL');
    
            const payload = { token: tokenFromUrl };
    
            const response = await fetch(urls.testsavailable, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) throw new Error('Failed to fetch available lab tests');
    
            const data = await response.json();
            setLabTestsAvailable(data);
        } catch (error) {
            console.error('Error fetching available lab tests:', error);
        }
    };
    
    const fetchAvailableRadiologyTests = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const tokenFromUrl = queryParams.get('token'); // Extract the token from the query string
            if (!tokenFromUrl) throw new Error('Token not found in URL');
    
            const payload = { token: tokenFromUrl };
    
            const response = await fetch(urls.radiologytestsavailable, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) throw new Error('Failed to fetch available radiology exams');
    
            const data = await response.json();
            setRadiologyTestsAvailable(data);
        } catch (error) {
            console.error('Error fetching available radiology exams:', error);
        }
    };
    
    const handleFieldChange = (fieldName, value) => {
        setEditableFields(prevState => {
            const updatedFields = {
                ...prevState,
                [fieldName]: value,
            };
            console.log(`Field ${fieldName} updated to:`, updatedFields[fieldName]);
            return updatedFields;
        });
    };

    const handleOptionToggle = (option) => {
        switch (option) {
            case 'doctor':
                setSelectionState(prevState => ({
                    ...prevState,
                    doctor: !prevState.doctor,
                }));
                break;
            case 'lab':
                setSelectionState(prevState => {
                    const wasSelected = prevState.lab;
                    if (wasSelected) {
                        // Reset lab test selection when unselected
                        setSelectedLabTests([]); // Assuming you're storing selected lab tests in a state
                        setShowLabTestPrompt(false);
                    } else {
                        setShowLabTestPrompt(true);
                    }
                    return {
                        ...prevState,
                        lab: !prevState.lab,
                    };
                });
                break;
            case 'radiology':
                setSelectionState(prevState => {
                    const wasSelected = prevState.radiology;
                    if (wasSelected) {
                        // Reset radiology test selection when unselected
                        setSelectedRadiologyTests([]); // Assuming you're storing selected radiology tests in a state
                        setShowRadiologyTestPrompt(false);
                    } else {
                        setShowRadiologyTestPrompt(true);
                    }
                    return {
                        ...prevState,
                        radiology: !prevState.radiology,
                    };
                });
                break;
            default:
                break;
        }
    };
    
    
    
  // Function to classify pulse rate
  const classifyPulseRate = (pulseRate) => {
    if (pulseRate < 60) {
        return { status: 'Warning: Low Pulse Rate', level: 'low' };
    } else if (pulseRate > 100) {
        return { status: 'Warning: High Pulse Rate', level: 'high' };
    } else {
        return { status: 'Pulse Rate Normal', level: 'normal' };
    }
};

// Function to classify blood pressure
const classifyBloodPressure = (bloodPressure) => {
    const [systolic, diastolic] = bloodPressure.split('/').map(Number);

    if (systolic < 90 || diastolic < 60) {
        return { status: 'Warning: Low Blood Pressure', level: 'low' };
    } else if (systolic > 140 || diastolic > 90) {
        return { status: 'Warning: High Blood Pressure', level: 'high' };
    } else {
        return { status: 'Blood Pressure Normal', level: 'normal' };
    }
};

// Effect to classify pulse rate when input changes
useEffect(() => {
    const pulse = parseInt(pulseRate, 10);

    if (!isNaN(pulse)) {
        setPulseRateStatus(classifyPulseRate(pulse));
    } else {
        setPulseRateStatus({ status: '', level: '' });
    }
}, [pulseRate]); // This effect only runs when pulse rate changes

// Effect to classify blood pressure when input changes
useEffect(() => {
    if (bloodPressure.includes('/')) {
        setBloodPressureStatus(classifyBloodPressure(bloodPressure));
    } else {
        setBloodPressureStatus({ status: '', level: '' });
    }
}, [bloodPressure]); // This effect only runs when blood pressure changes
useEffect(() => {
    const rate = parseInt(respiratoryRate, 10);
    if (!isNaN(rate)) {
        setRespiratoryRateStatus(classifyRespiratoryRate(rate));
    } else {
        setRespiratoryRateStatus('');
    }
}, [respiratoryRate]);

useEffect(() => {
    const spo2Value = parseFloat(spo2);
    if (!isNaN(spo2Value)) {
        setSpo2Status(classifySPO2(spo2Value));
    } else {
        setSpo2Status('');
    }
}, [spo2]);
  

 // Function to classify temperature
const classifyTemperature = (temperature) => {
    if (temperature < 35) {
        return 'Warning: Very Low Temperature';
    } else if (temperature >= 35 && temperature < 36.5) {
        return 'Warning: Low Temperature';
    } else if (temperature >= 36.5 && temperature <= 37.5) {
        return 'Temperature Normal';
    } else if (temperature > 37.5 && temperature <= 38.5) {
        return 'Warning: High Temperature';
    } else {
        return 'Warning: Very High Temperature';
    }
};


   // Function to classify respiratory rate
   const classifyRespiratoryRate = (rate) => {
    if (rate < 12) {
        return 'Warning: Low Respiratory Rate';
    } else if (rate > 20) {
        return 'Warning: High Respiratory Rate';
    } else {
        return 'Respiratory Rate Normal';
    }
};

// Function to classify SPO2
const classifySPO2 = (spo2) => {
    if (spo2 < 90) {
        return 'Warning: Low SPO2';
    } else {
        return 'SPO2 Normal';
    }
};  
  
    const getBMIWarning = (bmi) => {
        if (bmi < 18.5) {
            return 'Warning: Underweight';
        } else if (bmi >= 18.5 && bmi <= 24.9) {
            return 'Normal: Healthy weight';
        } else if (bmi >= 25 && bmi <= 29.9) {
            return 'Warning: Overweight';
        } else if (bmi >= 30) {
            return 'Warning: Obese';
        }
        return '';
    };
    const calculateBMI = (weight, height) => {
        if (weight && height) {
            const heightInMeters = height / 100; // Convert cm to meters
            return (weight / (heightInMeters * heightInMeters)).toFixed(2);
        }
        return '';
    };
    const styles = {
        lowSpo2: { color: 'red', fontWeight: 'bold' },
        normalSpo2: { color: 'green', fontWeight: 'bold' },
        lowRespiratoryRate: { color: 'red', fontWeight: 'bold' },
        normalRespiratoryRate: { color: 'green', fontWeight: 'bold' }
    };
    // Update BMI and warning message whenever bodyWeight or height changes
    useEffect(() => {
        const calculatedBmi = calculateBMI(bodyWeight, height);
        setBmi(calculatedBmi);
        setBmiWarning(getBMIWarning(calculatedBmi));
    }, [bodyWeight, height]);
    useEffect(() => {
        if (pulseRate.trim() === '') { // Check for an empty string or spaces
            setPulseRateClassification({ text: '', className: '' });
            return;
        }
    
        const pulse = parseInt(pulseRate, 10);
    
        if (!isNaN(pulse)) {
            setPulseRateClassification(classifyPulseRate(pulse));
        } else {
            setPulseRateClassification({ text: '', className: '' });
        }
    }, [pulseRate]);
    
    useEffect(() => {
        const temp = parseFloat(temperature);

        if (!isNaN(temp)) {
            setTemperatureStatus(classifyTemperature(temp));
        } else {
            setTemperatureStatus('');
        }
    }, [temperature]); // This effect only runs when temperature changes

        
    useEffect(() => {
        setBloodPressureClassification(classifyBloodPressure(systolicBloodPressure, diastolicBloodPressure));
    }, [systolicBloodPressure, diastolicBloodPressure]);
    
    useEffect(() => {
        setSpo2Classification(classifySPO2(spo2));
    }, [spo2]);
    
    useEffect(() => {
        setTemperatureClassification(classifyTemperature(temperature));
    }, [temperature]);
    
    useEffect(() => {
        setRespiratoryRateClassification(classifyRespiratoryRate(respiratoryRate));
    }, [respiratoryRate]);
    useEffect(() => {
        setPulseRateClassification(classifyPulseRate(pulseRate));
    }, [pulseRate]);  
                
    useEffect(() => {
        const systolic = parseInt(systolicBloodPressure, 10);
        const diastolic = parseInt(diastolicBloodPressure, 10);

        if (!isNaN(systolic) && !isNaN(diastolic)) {
            setClassification(classifyBloodPressure(systolic, diastolic));
        } else {
            setClassification({ text: '', className: '' });
        }
    }, [systolicBloodPressure, diastolicBloodPressure]);
    return (
        <div className="doctors-room-body">
            
            <div className="doctors-room-container2">
                <ToastContainer />
                <Topbar token={urlToken} />
                <h1>TRIAGE</h1>
    
                {/* Patient Details Section */}
                {displayReadOnlyFields()}
    
                <div className="patient-details-section">
                    <button onClick={() => setShowAddPatientPrompt(true)}>Insert Patient Details</button>
                    {showAddPatientPrompt && (
                        <div className="doctors-modal-overlay">
                            <div className="doctors-modal-content">
                                <div className="transaction-prompt">
                                    <h2>Insert Patient Details</h2>
    
                                    {/* Input fields for new patient details */}
                                    <div className="doctors-input-container">
                                        <label>First Name:</label>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={newPatientDetails.firstName}
                                            onChange={(e) => {
                                                setNewPatientDetails({ ...newPatientDetails, firstName: e.target.value });
                                                handleSuggestName(e.target.value);
                                            }}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    {/* Display suggested names */}
                                    {suggestions.map((patient, index) => (
                                        <div key={index} onClick={() => handleSelectSuggestedPatient(patient)} className="suggestion-item">
                                            {patient.first_name} {patient.last_name}
                                        </div>
                                    ))}
    
                                    {/* Other input fields for patient details */}
                                    <div className="input-container">
                                        <label>Last Name:</label>
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={newPatientDetails.lastName}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, lastName: e.target.value })}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    <div className="input-container">
                                        <label>Phone Number:</label>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            value={newPatientDetails.phoneNumber}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, phoneNumber: e.target.value })}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    <div className="input-container">
                                        <label>Age:</label>
                                        <input
                                            type="text"
                                            placeholder="Age"
                                            value={newPatientDetails.age}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, age: e.target.value })}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    <div className="input-container">
                                        <label>Sex:</label>
                                        <select
                                            value={newPatientDetails.sex}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, sex: e.target.value })}
                                            className="add-patient-input"
                                        >
                                            <option value="">Select Sex</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
    
                                    <div className="input-container">
                                        <label>Religion:</label>
                                        <select
                                            value={newPatientDetails.religion}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, religion: e.target.value })}
                                            className="add-patient-input"
                                        >
                                            <option value="">Select Religion</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Islamic">Islamic</option>
                                        </select>
                                    </div>
    
                                    <div className="input-container">
                                        <label>Address:</label>
                                        <input
                                        
                                            type="text"
                                            placeholder="Address"
                                            value={newPatientDetails.address}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, address: e.target.value })}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    <div className="input-container">
                                        <label>Date of Birth (month/date/year):</label>
                                        <input
                                            type="date"
                                            value={formatDate(newPatientDetails.dob)}
                                            onChange={(e) => setNewPatientDetails({ ...newPatientDetails, dob: e.target.value })}
                                            className="add-patient-input"
                                        />
                                    </div>
    
                                    <div className="transaction-buttons">
                                        <button onClick={handleCancel}>Cancel</button>
                                        <button onClick={addContact} disabled={savePatientClicked}>
                                            {savePatientClicked ? 'Please wait' : 'Save as New Patient'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
    
               <div className="vitals-section">
    <h2>Patient Vitals</h2>
    <div className="vitals-inputs">
        <div className="vitals-inputs-top">
        <div className="vital-input">
                <label>Blood Pressure (Systolic/Diastolic):</label>    
                <input
                    type="text"
                    placeholder="Enter Blood Pressure (e.g., 120/80)"
                    value={bloodPressure}
                    onChange={(e) => setBloodPressure(e.target.value)}
                />
                {bloodPressureStatus.status && (
                    <div className={`classification-message ${bloodPressureStatus.level}-pressure`}>
                        {bloodPressureStatus.status}
                    </div>
                )}
            </div>
            <div className="vital-input">
                <label>Pulse Rate (beats per minute):</label>
                <input
                    type="text"
                    placeholder="Enter Pulse Rate"
                    value={pulseRate}
                    onChange={(e) => setPulseRate(e.target.value)}
                />
                {pulseRateStatus.status && (
                    <div className={`classification-message ${pulseRateStatus.level}-pulse`}>
                        {pulseRateStatus.status}
                    </div>
                )}
            </div>

 
            <div className="vital-input">
    <label>Temperature (°C):</label>
    <input
        type="text"
        placeholder="Enter Temperature"
        value={temperature}
        onChange={(e) => {
            setTemperature(e.target.value);
            setTemperatureStatus(classifyTemperature(e.target.value));
        }}
    />
    <p
        className="classification-message"
        style={{
            color: temperatureStatus === 'Warning: Very High Temperature'
                ? 'red'
                : temperatureStatus === 'Warning: High Temperature'
                ? 'orange'
                : 'green'
        }}
    >
        {temperatureStatus}
    </p>
</div>
       
        </div>
        <div className="vitals-inputs-bottom">
        <div className="vital-input">
                <label>SPO2:</label>
                <input
                    type="text"
                    placeholder="Enter SPO2"
                    value={spo2}
                    onChange={(e) => setSpo2(e.target.value)}
                />
                <p 
                    className="classification-message" 
                    style={spo2Status.includes('Warning') ? styles.lowSpo2 : styles.normalSpo2}
                >
                    {spo2Status}
                </p>
            </div>
            <div className="vital-input">
                <label>Respiratory Rate:</label>
                <input
                    type="text"
                    placeholder="Enter Respiratory Rate"
                    value={respiratoryRate}
                    onChange={(e) => setRespiratoryRate(e.target.value)}
                />
                <p 
                    className="classification-message" 
                    style={
                        respiratoryRateStatus.includes('Warning') 
                            ? styles.lowRespiratoryRate 
                            : styles.normalRespiratoryRate
                    }
                >
                    {respiratoryRateStatus}
                </p>
            </div>
        
            <div className="vital-input">
                <label>Body Weight (kg):</label>
                <input
                    type="text"
                    placeholder="Body Weight"
                    value={bodyWeight}
                    onChange={(e) => setBodyWeight(e.target.value)}
                />
            </div>
            <div className="vital-input">
                <label>Height (cm):</label>
                <input
                    type="text"
                    placeholder="Height"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                />
            </div>
            <div className="vital-container">
  <div className="vital-input">
    <label>BMI:</label>
    <p className="bmi-value">
      {bmi ? bmi : 'Enter weight and height to calculate BMI'}
    </p>
  </div>
  {bmi && (
    <div className="vital-warning">
      <p className={`warning-message ${bmiWarning.includes('Warning') ? 'warning' : 'safe'}`}>
        {bmiWarning}
      </p>
    </div>
  )}
</div>

            </div>


        
    </div>
</div>
<div className="ptclassification-container">
    <label className="classification-label">Visit Classification:</label>
    <div className="classification-options">
        <div className="option-item">
            <input
                type="radio"
                id="referral"
                value="referral"
                checked={patientNature === 'referral'}
                onChange={() => setPatientNature('referral')}
                className="option-input"
            />
            <label htmlFor="referral" className="option-label">Referral</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="scheduled"
                value="scheduled"
                checked={patientNature === 'scheduled'}
                onChange={() => setPatientNature('scheduled')}
                className="option-input"
            />
            <label htmlFor="scheduled" className="option-label">Scheduled Appointment</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="emergency"
                value="emergency"
                checked={patientNature === 'emergency'}
                onChange={() => setPatientNature('emergency')}
                className="option-input"
            />
            <label htmlFor="emergency" className="option-label">Emergency</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="follow-up"
                value="follow-up"
                checked={patientNature === 'follow-up'}
                onChange={() => setPatientNature('follow-up')}
                className="option-input"
            />
            <label htmlFor="follow-up" className="option-label">Follow-up Visit</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="routine"
                value="routine"
                checked={patientNature === 'routine'}
                onChange={() => setPatientNature('routine')}
                className="option-input"
            />
            <label htmlFor="routine" className="option-label">Routine Check-up</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="consultation"
                value="consultation"
                checked={patientNature === 'consultation'}
                onChange={() => setPatientNature('consultation')}
                className="option-input"
            />
            <label htmlFor="consultation" className="option-label">Consultation</label>
        </div>

        <div className="option-item">
            <input
                type="radio"
                id="transfer"
                value="transfer"
                checked={patientNature === 'transfer'}
                onChange={() => setPatientNature('transfer')}
                className="option-input"
            />
           
            <label htmlFor="specific" className="option-label">Walk-in for Specific Services</label>
        </div>
    </div>
</div>


<div className="signs-container">
    <label className="signs-label">Any Information to pass on to the doctor?:</label>
    <textarea
        placeholder="You can type signs and symptoms of the client / Has the client come to see a specific doctor? / or anything you might need to pass on to the doctors"
        value={signsAndSymptoms}
        onChange={(e) => setSignsAndSymptoms(e.target.value)}
        className="signs-textarea"
    />
</div>


<div className="patient-actions-page">
    <div className="actions-container">
        <h2 className="actions-heading">Action for this Patient</h2>

        <div className="action-option">
            <input
                type="checkbox"
                id="doctor"
                name="action"
                value="doctor"
                checked={selectionState.doctor}
                onChange={() => handleOptionToggle('doctor')}
                className="action-input"
            />
            <label htmlFor="doctor" className="action-label">
                Send this patient to the doctor
            </label>
        </div>

        <div className="action-option">
            <input
                type="checkbox"
                id="lab"
                name="action"
                value="lab"
                checked={selectionState.lab}
                onChange={() => handleOptionToggle('lab')}
                className="action-input"
            />
            <label htmlFor="lab" className="action-label">
                Patient only wants to go to laboratory
            </label>
            {showLabTestPrompt && (
                <ul className="test-prompt-list">
                    {/* Display lab tests here */}
                </ul>
            )}
        </div>

        <div className="action-option">
            <input
                type="checkbox"
                id="radiology"
                name="action"
                value="radiology"
                checked={selectionState.radiology}
                onChange={() => handleOptionToggle('radiology')}
                className="action-input"
            />
            <label htmlFor="radiology" className="action-label">
                Patient only needs radiology services
            </label>
            {showRadiologyTestPrompt && (
                <ul className="test-prompt-list">
                    {/* Display radiology tests here */}
                </ul>
            )}
        </div>
    </div>

    {/* Container for selected lab and radiology tests */}
    <div className="selected-tests-container">
        {selectedLabTests.length > 0 && (
            <div className="selected-tests">
                <h6>Selected Lab Tests</h6>
                <ul>
                    {selectedLabTests.map((test, index) => (
                        <li key={index}>
                            {test}
                            <button
                                onClick={() => {
                                    setSelectedLabTests(prevTests => prevTests.filter(prevTest => prevTest !== test));
                                }}
                                className="remove-btn"
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        )}

        {selectedRadiologyTests.length > 0 && (
            <div className="selected-tests">
                <h6>Selected Radiology Exams</h6>
                <ul>
                    {selectedRadiologyTests.map((exam, index) => (
                        <li key={index}>
                            {exam}
                            <button
                                onClick={() => {
                                    setSelectedRadiologyTests(prevTests => prevTests.filter(prevTest => prevTest !== exam));
                                }}
                                className="remove-btn"
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>


 {/* Attention Level Selection */}
              <div className="attention-level-container">
    <h2 className="attention-heading">Attention Required</h2>
    <div className="attention-option">
        <input
            type="radio"
            id="critical"
            name="attention"
            value="critical"
            checked={attentionLevel === 'critical'}
            onChange={() => toggleAttentionLevel('critical')}
            
            className="attention-input"
        />
        <label htmlFor="critical" className="attention-label">Critical and Urgent Attention Needed</label>
    </div>

    <div className="attention-option">
        <input
            type="radio"
            id="average"
            name="attention"
            value="average"
            checked={attentionLevel === 'average'}
            onChange={() => toggleAttentionLevel('average')}
          
            className="attention-input"
        />
        <label htmlFor="average" className="attention-label">Moderate Attention Required</label>
    </div>

    <div className="attention-option">
        <input
            type="radio"
            id="routine"
            name="attention"
            value="routine"
            checked={attentionLevel === 'routine'}
            onChange={() => toggleAttentionLevel('routine')}
          
            className="attention-input"
        />
        <label htmlFor="routine" className="attention-label">Normal Routine</label>
    </div>
</div>


          

        </div>
                {/* Submit Button */}
                <button onClick={submitForm} disabled={submitting}>
                    {submitting ? 'Submitting...' : 'Submit Patient'}
                </button>

                {/* Lab and Radiology Tests */}
               {/* Modal for Lab Test Selection */}
{showLabTestPrompt && (
    <div
        className="unique-modal-overlay"
        onClick={(e) => {
            if (e.target.className === "unique-modal-overlay") {
                setShowLabTestPrompt(false);
                if (selectedLabTests.length === 0) {
                    setSelectionState(prevState => ({ ...prevState, lab: false }));
                }
            }
        }}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}
    >
        <div className="unique-modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '100px auto', width: '50%' }}>
            <h2>Select Lab Tests</h2>
            {labTestsAvailable.map(test => (
                <div key={test.name}>
                    <input
                        type="checkbox"
                        id={test.name}
                        value={test.name}
                        checked={selectedLabTests.includes(test.name)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedLabTests(prevTests => [...prevTests, test.name]);
                            } else {
                                setSelectedLabTests(prevTests => prevTests.filter(prevTest => prevTest !== test.name));
                            }
                        }}
                    />
                    <label htmlFor={test.name}>{test.name} - UGX {test.price}</label>
                </div>
            ))}
            <div
                style={{
                    position: 'sticky',
                    bottom: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderTop: '1px solid #ccc',
                }}
            >
                <button
                    onClick={() => {
                        setShowLabTestPrompt(false);
                        if (selectedLabTests.length === 0) {
                            setSelectionState(prevState => ({ ...prevState, lab: false }));
                        }
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        // Handle confirm logic here
                        setShowLabTestPrompt(false);
                    }}
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
)}

{/* Modal for Radiology Test Selection */}
{showRadiologyTestPrompt && (
    <div
        className="unique-modal-overlay"
        onClick={(e) => {
            if (e.target.className === "unique-modal-overlay") {
                setShowRadiologyTestPrompt(false);
                if (selectedRadiologyTests.length === 0) {
                    setSelectionState(prevState => ({ ...prevState, radiology: false }));
                }
            }
        }}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}
    >
        <div className="unique-modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '100px auto', width: '50%' }}>
            <h2>Select Radiology Tests</h2>
            {radiologyTestsAvailable.map(test => (
                <div key={test.name}>
                    <input
                        type="checkbox"
                        id={test.name}
                        value={test.name}
                        checked={selectedRadiologyTests.includes(test.name)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedRadiologyTests(prevTests => [...prevTests, test.name]);
                            } else {
                                setSelectedRadiologyTests(prevTests => prevTests.filter(prevTest => prevTest !== test.name));
                            }
                        }}
                    />
                    <label htmlFor={test.name}>{test.name} - UGX {test.price}</label>
                </div>
            ))}
            <div
                style={{
                    position: 'sticky',
                    bottom: '0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderTop: '1px solid #ccc',
                }}
            >
                <button
                    onClick={() => {
                        setShowRadiologyTestPrompt(false);
                        if (selectedRadiologyTests.length === 0) {
                            setSelectionState(prevState => ({ ...prevState, radiology: false }));
                        }
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        // Handle confirm logic here
                        setShowRadiologyTestPrompt(false);
                    }}
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
)}

</div>

            
        </div>
    );
};

export default Triage;
