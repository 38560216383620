import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './config.dev';
import './Dashboard.css';
import EmployeeOfTheMonth from './EmployeeOfTheMonth'; // Import the child component
import { useNavigate } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './SideBar'; // Import the Sidebar component
import { faDollarSign, faClipboardList, faNotesMedical , faUserNurse, faTimes, faUserMd, faRadiation, faCog, faBirthdayCake, faPills, faUser, faFlask, faShoppingCart, faAddressBook, faCalendar } from '@fortawesome/free-solid-svg-icons';

// Modal component
const Modal = ({ onClose, children }) => (
  <div className="modal">
    <div className="modal-content">
      {children}
      <button onClick={onClose}>Close</button>
    </div>
  </div>
);

function Dashboard() {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [action, setAction] = useState(null); // State to hold the selected action
  const [currentTime, setCurrentTime] = useState(new Date());
  const [employees, setEmployees] = useState([]);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const navigate = useNavigate();
  const adminPasswordRef = useRef(null);
  const securityCodeRef = useRef(null);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [birthdayCount, setBirthdayCount] = useState(0);
  const [securityCode, setSecurityCode] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clinicName, setClinicName] = useState('');
  const [switchState, setSwitchState] = useState({});
  const [token, setToken] = useState('');

  const [showAdminButton, setShowAdminButton] = useState(false);
  const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAup3FU135mAvJT6OheYW3
pQyWf6jvS4duUMY4cXrlJXyGqu8HqvTU0ewPy6w2HhCPxWboNclkAkPhOCc4URNT
x1Grg+mCsWmfhVimP2wtfmlBCJ09cyDMYf93iGj8RFf3CshY5yhppT/pX+RgTuXw
ClpOXe24CLG2VF9suNylk+ReAMLyOxaekYofAMBvvrD4+GYPJgvkTMXCXCKp2PnO
8+OjiltNMnoyqPEZoXHTV4EXtTrjYnwzSe0WZSSuzgVMhmtdx+IS4eisSumHV1eI
wBeZwI0bYGxDCedPRassmSFgTFqkkcgIXmEP1n5w/08S/QPr2G+myKTeRqp5RJA5
PQIDAQAB
-----END PUBLIC KEY-----`;


  useEffect(() => {
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    // Fetch clinic name using token
    const fetchClinicName = async () => {
      try {
        const response = await fetch(`${urls.fetchclinicname}?token=${token}`);
        const data = await response.json();
        if (response.ok) {
          setClinicName(data.clinic_name);
        } else if (data.error === "Session expired") {
          navigate('/login');
        } else {
          throw new Error('Failed to fetch clinic name');
        }
      } catch (error) {
        console.error('Error fetching clinic name:', error);
      }
    };

    fetchClinicName();
  }, [navigate]);


  useEffect(() => {
    const fetchBirthdayCount = async () => {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
  
      try {
        const response = await fetch(`${urls.birthdaycount}?token=${token}`);
        if (response.ok) {
          const data = await response.json();
          setBirthdayCount(data.birthday_count);
        } else {
          throw new Error('Failed to fetch birthday count');
        }
      } catch (error) {
        console.error('Error fetching birthday count:', error);
      }
    };
  
    fetchBirthdayCount();
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Fetch employees from the database
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchAppointmentCount = async () => {
      try {
        // Get the session token from the URL parameter
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
  
        // Set up the request with the session token
        const response = await fetch(`${urls.countappointments}?token=${token}`);
  
        if (response.ok) {
          const data = await response.json();
          setAppointmentCount(data.appointment_count);
        } else {
          throw new Error('Failed to fetch appointment count');
        }
      } catch (error) {
        console.error('Error fetching appointment count:', error);
      }
    };
  
    fetchAppointmentCount();
  }, []); // Run effect once on component mount
  
  const fetchEmployees = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    try {
      const response = await fetch(`${urls.fetchemployees}?token=${token}`);
      if (!response.ok) {
        throw new Error('Failed to fetch employees');
      }
      const data = await response.json();
      setEmployees(data);

      if (data.length === 0) {
        toast.info(
          <>
            <p>Please click on <strong>Settings (admin only)</strong> to add your employees to the system. This system is pre-loaded with <strong>UGX 200,000</strong> to allow you and your employees to familiarize yourselves with navigating through it. The admin password is <strong>12345</strong>; please change it to your preference.</p>
            <p><strong>Note:</strong> A UGX 10,000 daily charge only applies if the system is inactive and not being used. If the system remains inactive, the account may be deleted after 20 days. However, if you add employees, the system will charge approximately UGX 500 per employee per day.</p>
            <p><strong><em>Note: If you do not add employees, you will be charged UGX 10,000 per day.</em></strong></p>
          </>
        , { autoClose: false });
      }
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleEmployeeClick = (employeeName, action) => {
    setSelectedEmployee(employeeName);
    setAction(action); // Set the action
  };

  const handleLaboratoryButtonClick = () => {
    setAction('access-laboratory'); // Set the action to access-laboratory
    setShowModal(true); // Show the modal for security code input
  };
  
  // Function to handle the "Contacts" button click
  const handleContactsButtonClick = () => {
    handleButtonClick('contacts');
  };

  // Function to handle the "Patient Birthdays" button click
  const handleBirthdaysButtonClick = () => {
    handleButtonClick('birthdays');
  };
    // Function to handle the "Patient Birthdays" button click
    const handleTriageButtonClick = () => {
      handleButtonClick('triage');
    };

  // Function to handle the "Store" button click
  const handleStoreButtonClick = () => {
    handleButtonClick('store');
  };

    // Function to handle the "Nurse" button click
    const handleNurseButtonClick = () => {
      setAction('access-nurse'); // Set the action to access-nurse
      setShowModal(true); // Show the modal for security code input
    };

  // Function to handle the "Patient Appointments" button click
  const handlePatientAppointmentsButtonClick = () => {
    handleButtonClick('patient-appointments');
  };

// Function to handle the "Doctors Room" button click
const handleDoctorsRoomButtonClick = () => {
  setAction('access-doctors-room'); // Set the action to access-doctors-room
  setShowModal(true); // Show the modal for security code input
};

const confirmActionLab = async () => {
  try {
    const response = await fetch(urls.code, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employee: selectedEmployee,
        action: 'access-laboratory',
        securityCode: securityCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.result === 'yes') {
        const permitResponse = await fetch(urls.permit, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employee: selectedEmployee,
            action: 'access-laboratory',
            securityCode: securityCode,
          }),
        });

        if (permitResponse.ok) {
          const permitData = await permitResponse.json();
          if (permitData.result === 'yes') {
            // Check if permission is granted
            navigate(`/labdashboard/${selectedEmployee}`); // Navigate to Laboratory dashboard route
          } else {
            alert('Permission not granted. Please contact the administrator.');
          }
        } else {
          throw new Error('Failed to get permit response');
        }
      } else {
        alert('Incorrect security code. Please try again.');
      }
    } else {
      throw new Error('Failed to check security code');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

// Function to handle the "Radiographer" button click
const handleRadiographerButtonClick = () => {
  setAction('access-radiographer'); // Set the action to access-radiographer
  setShowModal(true); // Show the modal for security code input
};

const confirmActionRadiographer = async () => {
  try {
    const response = await fetch(urls.code, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employee: selectedEmployee,
        action: 'access-radiographer',
        securityCode: securityCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.result === 'yes') {
        const permitResponse = await fetch(urls.permit, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employee: selectedEmployee,
            action: 'access-radiographer',
            securityCode: securityCode,
          }),
        });

        if (permitResponse.ok) {
          const permitData = await permitResponse.json();
          if (permitData.result === 'yes') {
            // Check if permission is granted
            navigate(`/radiographer/${selectedEmployee}`); // Navigate to Radiographer dashboard route
          } else {
            alert('Permission not granted. Please contact the administrator.');
          }
        } else {
          throw new Error('Failed to get permit response');
        }
      } else {
        alert('Incorrect security code. Please try again.');
      }
    } else {
      throw new Error('Failed to check security code');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

const confirmActionDoctor = async () => {
  try {
    const response = await fetch(urls.code, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employee: selectedEmployee,
        action: 'access-doctors-room' ,
        securityCode: securityCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.result === 'yes') {
        const permitResponse = await fetch(urls.permit, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employee: selectedEmployee,
            action: action,
            securityCode: securityCode,
          }),
        });

        if (permitResponse.ok) {
          const permitData = await permitResponse.json();
          if (permitData.result === 'yes') {
            // Check if permission is granted
            navigate(`/doctorsdashboard/${selectedEmployee}`); // Navigate to Doctors Room route
          } else {
            alert('Permission not granted. Please contact the administrator.');
          }
        } else {
          throw new Error('Failed to get permit response');
        }
      } else {
        alert('Incorrect security code. Please try again.');
      }
    } else {
      throw new Error('Failed to check security code');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

const confirmActionNurse = async () => {
  try {
    const response = await fetch(urls.code, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employee: selectedEmployee,
        action: 'access-nurse',
        securityCode: securityCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.result === 'yes') {
        const permitResponse = await fetch(urls.permit, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employee: selectedEmployee,
            action: 'access-nurse',
            securityCode: securityCode,
          }),
        });

        if (permitResponse.ok) {
          const permitData = await permitResponse.json();
          if (permitData.result === 'yes') {
            // Check if permission is granted
            navigate(`/nurse-dashboard/${selectedEmployee}`); // Navigate to Nurse dashboard route
          } else {
            alert('Permission not granted. Please contact the administrator.');
          }
        } else {
          throw new Error('Failed to get permit response');
        }
      } else {
        alert('Incorrect security code. Please try again.');
      }
    } else {
      throw new Error('Failed to check security code');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
  const handleButtonClick = (action) => {
    setAction(action);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAction(null); // Clear the action when modal is closed
    setSecurityCode(''); // Clear security code
  };

  const confirmAction = async () => {
    try {
        setLoading(true); // Set loading state to true when the button is clicked

        // Encrypt the security code with the public key
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptedSecurityCode = encrypt.encrypt(securityCode);

        // Fetch token from URL parameter
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        // First request to check security code
        const response = await fetch(urls.code, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                employee: selectedEmployee,
                action: action,
                securityCode: encryptedSecurityCode, // Send the encrypted security code
                token: token // Include token
            }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.result === 'yes') {
                // Second request to get permit
                const permitResponse = await fetch(urls.permit, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        employee: selectedEmployee,
                        action: action,
                        token: token // Include token
                    
                    
                      }),
                });

                if (permitResponse.ok) {
                    const permitData = await permitResponse.json();
                    if (permitData.result === 'yes') {
                        // Permission granted, navigate to corresponding page with login token
                        const loginToken = permitData.login_token;
                        navigate(`/${action}/?token=${loginToken}`);
                    } else if (permitData.redirectUrl) {
                        // Subscription expired, redirect to payment
                        const confirmed = window.confirm('Sorry, your subscription expired. Please press OK to proceed and make a payment.');

                        if (confirmed) {
                            window.location.href = permitData.redirectUrl; // Redirect to the received URL
                        }
                    } else {
                        // Permission not granted
                        toast.warning('Permission not granted. Please contact the administrator.');
                    }
                } else {
                    throw new Error('Failed to get permit response');
                }
            } else {
                // data.result is not 'yes', handle invalid security code
                toast.error('Invalid security code. Please try again.');
            }
        } else {
            throw new Error('Failed to check security code');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again later.');
    } finally {
        setLoading(false); // Reset loading state after the action is completed
    }
};

  const handleAdminButtonClick = () => {
    setShowAdminModal(true);
  };
  


const handleAdminPasswordSubmit = async () => {
  setLoading(true);
  try {
    if (adminPassword) {
      // Encrypt the admin password with the public key
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encryptedAdminPassword = encrypt.encrypt(adminPassword);

      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (!token) {
        toast.error('Token not found in URL. Please provide a valid token.');
        return;
      }

      const payload = {
        employee: 'admin', // Employee name as 'admin'
        adminPassword: encryptedAdminPassword, // Send the encrypted admin password
        token: token // Use the token fetched from URL parameter
      };

      const response = await fetch(urls.permitadmin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        
        if (data.result === 'yes') {
          const loginToken = data.login_token;
          navigate(`/admin-dashboard?token=${loginToken}`); // Navigate to admin dashboard with login token
        } else if (data.redirectUrl) {
          const confirmed = window.confirm('Sorry, your subscription expired. Please press OK to proceed and make a payment.');

          if (confirmed) {
            window.location.href = data.redirectUrl; // Redirect to the received URL
          }
        } else {
          toast.warning('Permission not granted. Please contact clinic pro customer care for help.');
        }
      } else {
        const errorData = await response.json();
        if (errorData.error === 'Invalid admin password') {
          toast.error('Invalid admin password');
        } else {
          throw new Error('Failed to get permit response');
        }
      }
    } else {
      toast.error('Admin password cannot be empty. Please try again.');
    }
  } catch (error) {
    console.error('Error:', error);
    toast.error('An unexpected error occurred. Please try again later.');
  } finally {
    setShowAdminModal(false);
    setAdminPassword('');
    setLoading(false);
  }
};


  const handleGenerateDrugOrderClick = () => {
    navigate('/generate-drug-order');
  };
  const handleSidebarOptionClick = (option) => {
    switch (option) {
      case 'admin-dashboard':
        navigate('/admin-dashboard');
        break;
      case 'manage-drug-scope':
        navigate('/manage-drug-scope');
        break;
      case 'make-drug-order':
        navigate('/generate-drug-order');
        break;
      case 'billing':
        navigate('/billing');
        break;
      case 'marketing':
        navigate('/marketing');
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    setToken(tokenFromUrl);
  }, []);
  useEffect(() => {
    if (showAdminModal && adminPasswordRef.current) {
      adminPasswordRef.current.focus();
    }
  }, [showAdminModal]);

  useEffect(() => {
    if (showModal && securityCodeRef.current) {
      securityCodeRef.current.focus();
    }
  }, [showModal]);
  
  
  return (
    <div className="flex-container" style={{ fontFamily: 'Arial, sans-serif' }}>
       <div>
      {token && <EmployeeOfTheMonth token={token} />} {/* Pass the token to the child component */}
    </div>
       <ToastContainer />
  <Sidebar
  onSelectOption={handleSidebarOptionClick}
  navigate={navigate}
  setShowAdminModal={setShowAdminModal}
  setShowModal={setShowModal}
  publicKey={publicKey}
  selectedEmployee={selectedEmployee}
 
  token={token}
/>
     <div className="content-container">
      <div className="welcome-dashboard-container">
      <h1 className="title" style={{ fontSize: '4.5rem' }}>{clinicName}</h1>
       
        <h2 className="date">{currentTime.toLocaleString('en-US', { timeZone: 'Africa/Kampala' })}</h2>
        <p>Please select your name before you perform any action on this platform</p>
        
        <div className="additional-buttons">
          <button
            className={`menu-button sales-button ${action === 'sales' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('sales')}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faDollarSign} className="fa-icon" /> Cashier
          </button>
          <button
            className={`menu-button credits-button ${action === 'credits' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('credits')}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faClipboardList} className="fa-icon" /> Billing
          </button>
          <button
            className={`menu-button selldrugs-button ${action === 'selldrugs' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('selldrugs')}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faPills} className="fa-icon" /> Dispensary/shelves
          </button>
        {/*}  <button
            className={`menu-button birthdays-button ${action === 'birthdays' ? 'selected' : ''}`}
            onClick={handleBirthdaysButtonClick}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faBirthdayCake} className="fa-icon" /> Pt Birthdays
            {birthdayCount > 0 && <span className="appointment-indicator">{birthdayCount}</span>}
          </button>*/}
          <button
            className={`menu-button triage-button ${action === 'triage' ? 'selected' : ''}`}
            onClick={handleTriageButtonClick}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faNotesMedical } className="fa-icon" /> Triage
            
          </button>
          <button
            className={`menu-button radiographer-button ${action === 'access-radiographer' ? 'selected' : ''}`}
            onClick={handleRadiographerButtonClick}
            disabled={!selectedEmployee}
          >
            <FontAwesomeIcon icon={faRadiation} className="fa-icon" /> Radiographer
          </button>
        </div>

        <div className="employee-buttons">
      
      {loading ? (
        <div>Loading...</div>
      ) : employees.length > 0 ? (
        employees.map((employee) => (
          <button
            key={employee.Name}
            className={`btn ${selectedEmployee === employee.Name ? 'selected' : ''}`}
            onClick={() => handleEmployeeClick(employee.Name, action)}
          >
            <FontAwesomeIcon icon={faUser} className="fa-icon" /> {employee.Name}
          </button>
        ))
      ) : null }
    </div>
  

      </div>
      
      <div className="additional-buttons2">
        <button
          className={`menu-button store-button ${action === 'store' ? 'selected' : ''}`}
          onClick={handleStoreButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faShoppingCart} className="fa-icon" /> Store
        </button>
        <button
          className={`menu-button contacts-button ${action === 'contacts' ? 'selected' : ''}`}
          onClick={handleContactsButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faAddressBook} className="fa-icon" /> Patient Details
        </button>
        <button
          className={`menu-button appointments-button ${action === 'patient-appointments' ? 'selected' : ''}`}
          onClick={handlePatientAppointmentsButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faCalendar} className="fa-icon" /> Pt Appointments
          {appointmentCount > 0 && <span className="appointment-indicator">{appointmentCount}</span>}
        </button>
        <button
          className={`menu-button doctors-room-button ${action === 'access-doctors-room' ? 'selected' : ''}`}
          onClick={handleDoctorsRoomButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faUserMd} className="fa-icon" /> Doctor
        </button>
        <button
          className={`menu-button lab-button ${action === 'access-laboratory' ? 'selected' : ''}`}
          onClick={handleLaboratoryButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faFlask} className="fa-icon" /> Lab
        </button>
        <button
          className={`menu-button nurse-button ${action === 'access-nurse' ? 'selected' : ''}`}
          onClick={handleNurseButtonClick}
          disabled={!selectedEmployee}
        >
          <FontAwesomeIcon icon={faUserNurse} className="fa-icon" /> Nurse
        </button>
      </div>
      <footer className="footer2">
            This system was created by DeepMind E-Systems. For support or help contact +256786747733
          </footer>
          {showAdminModal && (
        <Modal onClose={() => setShowAdminModal(false)}>
          <input
            type="password"
            placeholder="Enter admin password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            ref={adminPasswordRef} // Attach ref here
          />
          <button onClick={handleAdminPasswordSubmit}>
            {loading ? 'Please wait...' : 'Proceed'}
          </button>
        </Modal>
      )}

      {showModal && (
        <Modal onClose={handleCloseModal}>
          <input
            type="password"
            placeholder="Enter your individual password "
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
            ref={securityCodeRef} // Attach ref here
          />
          <button onClick={confirmAction}>
            {loading ? 'Please wait...' : 'Proceed'}
          </button>
        </Modal>


      )}
    </div>
  </div>
);
      }
export default Dashboard;