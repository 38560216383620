import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './NurseDashboard.css'; // Assuming you have defined styles in NurseDashboard.css
import doctorsSvg from './images/nurses.svg'; // Import the SVG image
import Topbar from './Topbar'; // Import the Topbar component

function NurseDashboard() {
  const [employeeName, setEmployeeName] = useState('');
  const [patientsToCall, setPatientsToCall] = useState([]);
  const [currentPatients, setCurrentPatients] = useState([]);
  const navigate = useNavigate();
  const getTokenFromUrlOrLocalStorage = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    return tokenFromUrl || localStorage.getItem('token');
  };
  // Define urlToken using the function
const urlToken = getTokenFromUrlOrLocalStorage();

  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
        console.log('URL Token:', tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            localStorage.setItem('token', securityData.clinic_session_token);
            fetchPatientsToCall(securityData.clinic_session_token);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);

  const fetchPatientsToCall = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.fetchpatientscall, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched patients:', data);
        setPatientsToCall(data.patients);
      } else {
        throw new Error('Failed to fetch patients to call');
      }
    } catch (error) {
      console.error('Error fetching patients to call:', error);
    }
  };
  
  useEffect(() => {
    const intervalId = setInterval(fetchPatientsToCall, 10000);
    return () => clearInterval(intervalId);
  }, [fetchPatientsToCall]);

  useEffect(() => {
    const selectRandomPatients = () => {
      if (patientsToCall.length > 1) {
        const selectedIndices = new Set();
        while (selectedIndices.size < 10 && selectedIndices.size < patientsToCall.length) {
          selectedIndices.add(Math.floor(Math.random() * patientsToCall.length));
        }
        const selectedPatients = Array.from(selectedIndices).map(index => patientsToCall[index]);
        setCurrentPatients(selectedPatients);
      }
    };
  
    const intervalId = setInterval(selectRandomPatients, 150000);
    selectRandomPatients(); // Initial selection
  
    return () => clearInterval(intervalId);
  }, [patientsToCall]);
  

  const handleViewPatientFiles = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    navigate(`/patientfiles2/?token=${tokenFromUrl}`);
  };

  return (
    <div className="dashboard-container">
           <Topbar token={urlToken} />
      <div className="nurse-dashboard-container">
        <h1>Hello, {employeeName}</h1>
        <img src={doctorsSvg} alt="nurses's Icon" className="nurses-icon" />
        <p>The following patients were here a few days ago. Please give them a call and let us know how they are doing:</p>
        <div className="patient-names-container">
          {currentPatients.map((patient, index) => (
            <div key={index} className="patient-details">
              <span className="patient-name">{patient?.first_name}</span>
              <span className="patient-phone">{patient?.phone_number}</span>
            </div>
          ))}
        </div>
        <button className="view-patient-files-button" onClick={handleViewPatientFiles}>
          View Patient Files
        </button>
      </div>
    </div>
  );
}

export default NurseDashboard;
