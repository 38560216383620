import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import Resultmodal from './Resultmodal';
import RadiologyResultsModal2 from './RadiologyResultsModal2';
import './PatientsFiles.css';

function Walkin({ onClose }) {
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [isLabResultsModalOpen, setIsLabResultsModalOpen] = useState(false);
    const [isRadiologyResultsModalOpen, setIsRadiologyResultsModalOpen] = useState(false);
    const [employeeName, setEmployeeName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [clinicDetails, setClinicDetails] = useState(null);

    const navigate = useNavigate();
    let abortController = new AbortController();

    useEffect(() => {
        const fetchTokenAndCheckSecurity = async () => {
            try {
                const tokenFromUrl = new URLSearchParams(window.location.search).get('token');
                const securityResponse = await fetch(urls.security, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token: tokenFromUrl }),
                });

                if (!securityResponse.ok) throw new Error('Security check failed');
                const securityData = await securityResponse.json();

                if (securityData.message === 'Session valid') {
                    setEmployeeName(securityData.employee_name);
                    localStorage.setItem('token', securityData.clinic_session_token);
                    fetchPatients(tokenFromUrl);
                    await fetchClinicDetails(tokenFromUrl);
                } else if (securityData.error === 'Session expired') {
                    navigate(`/dashboard?token=${securityData.clinic_session_token}`);
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Security check error:', error);
                navigate('/login');
            }
        };
        fetchTokenAndCheckSecurity();
    }, [navigate]);

    const fetchPatients = useCallback(async (tokenFromUrl) => {
        abortController.abort();
        abortController = new AbortController();
        setLoading(true);

        try {
            let apiUrl = `${urls.suggestfiles2}?query=${searchQuery}`;
            if (selectedDate) apiUrl += `&date=${selectedDate}`;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: tokenFromUrl }),
                signal: abortController.signal,
            });

            if (!response.ok) throw new Error('Failed to fetch patients');
            const data = await response.json();
            setPatients(data);
        } catch (error) {
            console.error('Error fetching patient data:', error);
        } finally {
            setLoading(false);
        }
    }, [searchQuery, selectedDate]);

    const fetchClinicDetails = async (token) => {
        try {
            const response = await fetch(urls.fetchclinicdetails, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            });

            if (!response.ok) throw new Error('Failed to fetch clinic details');
            const data = await response.json();
            setClinicDetails(data);
        } catch (error) {
            console.error('Error fetching clinic details:', error);
        }
    };

    const handleViewEditPatientFile = (fileId) => {
        const tokenFromUrl = new URLSearchParams(window.location.search).get('token');
        navigate(`/patient-file/${tokenFromUrl}/${fileId}`);
    };

    const handleShowLabResults = (patient) => {
        setSelectedPatient(patient);
        setIsLabResultsModalOpen(true);
    };

    const handleShowRadiologyResults = (patient) => {
        setSelectedPatient(patient);
        setIsRadiologyResultsModalOpen(true);
    };

    const handleSearchChange = (event) => setSearchQuery(event.target.value);

    const handleDateChange = (event) => setSelectedDate(event.target.value);

    const handleClose = () => onClose();

    return (
        <div
            className="patients-page-container"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                backgroundColor: 'white',
                overflowY: 'auto',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                <h1 className="custom-heading">
                    THESE CLIENTS CAME IN REQUESTING ONLY FOR RADIOLOGY OR LABORATORY SERVICES.
                </h1>
                <button
                    onClick={handleClose}
                    style={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '20px',
                        cursor: 'pointer',
                    }}
                    aria-label="Close"
                >
                    X
                </button>
            </div>

            <div className="search-container" style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                        padding: '10px',
                        fontSize: '16px',
                        width: '300px',
                        marginRight: '10px',
                    }}
                />
                <div className="date-picker-container" style={{ display: 'inline-block' }}>
                    <label htmlFor="date-picker">Search by Date:</label>
                    <input
                        id="date-picker"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                    />
                </div>
            </div>

            {loading ? (
                <p>Loading patient files...</p>
            ) : (
                <div
                    className="patient-list"
                    style={{
                        flex: '1',
                        overflowY: 'auto',
                        padding: '10px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                    }}
                >
                    {patients.length > 0 ? (
                        patients.map(patient => (
                            <div
                                key={patient.contact_id}
                                className="patient-card"
                                style={{
                                    padding: '20px',
                                    marginBottom: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <h3>{`${patient.first_name} ${patient.last_name}`}</h3>
                                <p>{`File ID: ${patient.file_id}`}</p>
                                <p>{`Age: ${patient.age}, Sex: ${patient.sex}`}</p>
                                <p>{`Date & Time: ${patient.date_time}`}</p>

                                {patient.lab_status_message && (
                                    <p>
                                        {patient.lab_status_message === 'Click here to view Lab results' ? (
                                            <span
                                                className="clickable-status"
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={() => handleShowLabResults(patient)}
                                            >
                                                {patient.lab_status_message}
                                            </span>
                                        ) : (
                                            patient.lab_status_message
                                        )}
                                    </p>
                                )}

                                {patient.radiology_status_message && (
                                    <p>
                                        {patient.radiology_status_message === 'Click here to view Radiology results' ? (
                                            <span
                                                className="clickable-status"
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={() => handleShowRadiologyResults(patient)}
                                            >
                                                {patient.radiology_status_message}
                                            </span>
                                        ) : (
                                            patient.radiology_status_message
                                        )}
                                    </p>
                                )}

                                <button
                                    onClick={() => handleViewEditPatientFile(patient.file_id)}
                                    style={{
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding: '10px 15px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    View/Edit
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No patients found.</p>
                    )}
                </div>
            )}

{isLabResultsModalOpen && selectedPatient && (
                <Resultmodal 
                    patient={selectedPatient} 
                    onClose={() => setIsLabResultsModalOpen(false)} 
                    clinicDetails={clinicDetails} // Pass clinic details to Resultmodal
                />
            )}
            {isRadiologyResultsModalOpen && selectedPatient && (
                <RadiologyResultsModal2 
                    patient={selectedPatient} 
                    onClose={() => setIsRadiologyResultsModalOpen(false)} 
                    clinicDetails={clinicDetails} // Pass clinic details to RadiologyResultsModal2
                />
            )}
        </div>
    );
}

export default Walkin;
